
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

export default ({ dir, type, records, columns }) => {
  return (
    <div className="image-grid">
      {columns.map((column, idx) =>
        <div key={idx} className="image-grid-column">
          <div className="image-grid-header">
            {column.title}
          </div>
          {records.map((record) =>
            <Link to={`/${type}/${record.id}`} className="image-grid-cell" key={record.id}>
              {column.imageSuffix ? <ImageGridCell url={`https://images.ufastats.com/${dir}/${record.imageId}-${column.imageSuffix}.png`} /> : record[column.key]}
            </Link>,
          )}
        </div>,
      )}
    </div>
  );
};

const ImageGridCell = ({ url }) => {
  const [dimensions, setDimensions] = useState('Loading...');
  const ref = useRef();

  const getDimensions = () => {
    if (ref.current) {
      setDimensions(`${ref.current.naturalWidth}x${ref.current.naturalHeight}`);
    }
  };

  return (
    <div className="image-grid-image">
      <img src={url} alt={url} ref={ref} onError={() => setDimensions('Invalid image!')} onLoad={() => getDimensions()} />
      <p>{dimensions}</p>
    </div>
  );
};
