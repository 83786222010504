import ImageGrid from '../components/ImageGrid';
import teams from '../constants/teams.json';

const COLUMNS = [
  { key: 'name', title: 'Team' },
  { imageSuffix: 'centered', title: 'Centered' },
  { imageSuffix: 'away', title: 'Away' },
  { imageSuffix: 'home', title: 'Home' },
  { imageSuffix: 'centered2', title: 'Centered 2' },
];

const TEAMS = teams.map((team) => {
  return {
    name: team.name,
    imageId: team.abbrev,
    id: team.teamId,
  };
});

export default () => {
  return (
    <div className="teams-page">
      <div className="teams">
        <h2>Teams</h2>
        <ImageGrid dir="logos" type="team" records={TEAMS} columns={COLUMNS} />
      </div>
    </div>
  );
};
