import { useEffect } from 'react';
import teamsMap from '../constants/teamsMap';
import { useParams, useNavigate } from 'react-router-dom';
import ImageUploader from '../components/ImageUploader';

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const team = teamsMap.byExternal[params.extTeamId];

  useEffect(() => {
    if (!team) {
      navigate('/teams', { replace: true });
    }
  }, [team, navigate]);

  if (!team) { return; }
  return (
    <div className="team-page">
      <h2>{team.name}</h2>
      <ImageUploader path={`logos/${team.abbrev}-centered.png`} />
      <ImageUploader path={`logos/${team.abbrev}-away.png`} />
      <ImageUploader path={`logos/${team.abbrev}-home.png`} />
      <ImageUploader path={`logos/${team.abbrev}-centered2.png`} />
    </div>
  );
};
