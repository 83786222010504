import { useState, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'semantic-ui-react';
import uploadToS3 from '../utils/uploadToS3';

export default ({ path }) => {
  const [newFile, setNewFile] = useState();
  const [uploadStatus, setUploadStatus] = useState();
  const [imageRefresh, setImageRefresh] = useState(0);
  const [oldDimensions, setOldDimensions] = useState('Loading...');
  const [newDimensions, setNewDimensions] = useState('Loading...');
  const oldImageRef = useRef();
  const newImageRef = useRef();
  const url = `https://images.ufastats.com/${path}?${imageRefresh}`;

  const getDimensions = (ref, set) => {
    if (ref.current) {
      set(`${ref.current.naturalWidth}x${ref.current.naturalHeight}`);
    } else {
      set('Not a valid image!');
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setNewFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled: !!uploadStatus,
  });

  const upload = async () => {
    setUploadStatus('Uploading');
    const monitorCache = await uploadToS3(path, newFile);
    if (typeof monitorCache === 'function') {
      setUploadStatus('Clearing Cache');
      await monitorCache();
      setImageRefresh(imageRefresh + 1);
    }
    setUploadStatus();
  };

  return (
    <div className="image-uploader">
      <h4>{path}</h4>
      <div className="image-uploader-container">
        <img src={url} alt={`Stored at ${path}`} ref={oldImageRef} onError={() => setOldDimensions('Invalid image!')} onLoad={() => getDimensions(oldImageRef, setOldDimensions)} />
        <div className="image-metadata">
          <p>{`Dimensions: ${oldDimensions}`}</p>
          <Button primary onClick={() => setImageRefresh(imageRefresh + 1)}>Refresh</Button>
        </div>
        <div className="image-uploader-box" {...getRootProps()}>
          <input {...getInputProps()} />
          {newFile ? <img src={URL.createObjectURL(newFile)} alt="New Upload" ref={newImageRef} onError={() => setNewDimensions('Invalid image!')} onLoad={() => getDimensions(newImageRef, setNewDimensions)} /> : <img src={require('../assets/images/drag-and-drop.png')} alt="Drag and Drop here" />}
        </div>
        {newFile ? <div className="image-metadata">
          <p>{`Dimensions: ${newDimensions}`}</p>
          <Button primary onClick={upload} disabled={!!uploadStatus}>{uploadStatus ? uploadStatus : 'Upload'}</Button>
          <Button negative onClick={() => setNewFile()} style={{ marginTop: '20px' }} disabled={!!uploadStatus}>Delete</Button>
        </div> : <div className="image-metadata" />}
      </div>
    </div>
  );
};
