import teamsList from './teams.json';

export const teamsMap = {
  byInternal: {},
  byExternal: {},
};
for (const team of teamsList) {
  teamsMap.byInternal[team.id] = team;
  teamsMap.byExternal[team.teamId] = team;
}

export default teamsMap;
