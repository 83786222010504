import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const NAV_ITEMS = [
  { title: 'Teams', path: '/teams' },
  { title: 'Players', path: '/players' },
];

export default ({ signOut }) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-title link" onClick={() => navigate('/')}>
          UFA Director
        </div>
        <div className="header-nav">
          {NAV_ITEMS.map(({ title, path }, i) => <div className="header-nav-item" key={i}>
            <Button primary onClick={() => navigate(path)} className="header-logout">{title}</Button>
          </div>)}
        </div>
        <Button primary onClick={signOut} className="header-logout">Log Out</Button>
      </div>
    </div>
  );
};
