import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dropdown, Loader } from 'semantic-ui-react';
import ImageGrid from '../components/ImageGrid';
import teams from '../constants/teams.json';
import request from '../utils/request';
import Error from '../components/Error';
import teamsMap from '../constants/teamsMap';

const COLUMNS = [
  { key: 'name', title: 'Player' },
  { imageSuffix: 'primary', title: 'Primary' },
  { imageSuffix: 'headshot', title: 'Headshot' },
  { imageSuffix: 'ai', title: 'AI' },
];

const YEARS = [
  { key: 2024, value: 2024, text: 2024 },
  { key: 2023, value: 2023, text: 2023 },
  { key: 2022, value: 2022, text: 2022 },
];

const TEAMS = teams.map((team) => {
  return {
    key: team.teamId,
    value: team.teamId,
    text: team.name,
  };
});

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [year, setYear] = useState(Number(searchParams.get('year')) || 2024);
  const [team, setTeam] = useState(searchParams.get('team') || 'hustle');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();

  const changeYear = (value) => {
    setSearchParams({ team, year: value });
    setYear(value);
    setLoading(true);
  };
  const changeTeam = (value) => {
    setSearchParams({ team: value, year });
    setTeam(value);
    setLoading(true);
  };
  useEffect(() => {
    async function fetchData() {
      const teamDef = teamsMap.byExternal[team];
      if (!teamDef) {
        setError('You must select a team before you can view players.');
      } else if (!Number.isInteger(teamDef.id)) {
        setError('Cannot query team. Contact Ben Nelson to investigate.');
      } else if (!Number.isInteger(year)) {
        setError('Cannot query year. Contact Ben Nelson to investigate.');
      } else {
        setLoading(true);
        setError();
        const response = await request('get', `${process.env.REACT_APP_EXPRESS_URL}players?team=${teamDef.id}&year=${year}`);
        if (response.error) {
          setError(response.errorMessage);
        } else if (!Array.isArray(response)) {
          setError('Server returned invalid data. Contact Ben Nelson to investigate.');
        } else {
          setData(response.map((player) => {
            return {
              name: player.name,
              imageId: player.extPlayerId,
              id: player.extPlayerId,
            };
          }));
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [team, year]);
  return (
    <div className="players-page">
      <div className="players-page-options">
        <Dropdown
          placeholder="Select Team"
          className="team-dropdown"
          search
          selection
          value={team}
          onChange={(_, e) => changeTeam(e.value)}
          options={TEAMS}
        />
        <Dropdown
          placeholder="Select Year"
          className="year-dropdown"
          search
          selection
          value={year}
          onChange={(_, e) => changeYear(e.value)}
          options={YEARS}
        />
      </div>
      {loading ? <Loader active /> :
        <div className="players">
          {error ? <Error message={error} /> :
            <>
              <h2>Players</h2>
              <ImageGrid dir="players" type="player" records={data} columns={COLUMNS} />
            </>
          }
        </div>
      }
    </div>
  );
};
