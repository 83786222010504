import { CloudFrontClient, CreateInvalidationCommand, GetInvalidationCommand } from '@aws-sdk/client-cloudfront';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import request from './request';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

const DISTRIBUTION_ID = 'E2SIVMARLPWVHO';
let credentials;

export default async (path, file) => {
  if (!credentials || DateTime.now().diff(DateTime.fromISO(credentials.expiration)).as('minutes') > -30) {
    const response = await request('get', `${process.env.REACT_APP_EXPRESS_URL}awsCredentials`);
    if (response.error) {
      toast.error('Error getting credentials to upload images: ' + response.errorMessage);
      return;
    }
    credentials = response;
  }
  const s3Input = {
    Body: file,
    Bucket: 'audl-broadcast-images',
    Key: path,
  };
  const putObjectCommand = new PutObjectCommand(s3Input);
  try {
    const s3Client = new S3Client({ credentials, region: 'us-east-2' });
    await s3Client.send(putObjectCommand);
    const cloudFrontInput = {
      DistributionId: DISTRIBUTION_ID,
      InvalidationBatch: {
        Paths: {
          Quantity: 1,
          Items: [
            '/' + path,
          ],
        },
        CallerReference: `video-director-${DateTime.now().toISO()}`,
      },
    };
    const createInvalidationCommand = new CreateInvalidationCommand(cloudFrontInput);
    try {
      const cloudFrontClient = new CloudFrontClient({ credentials, region: 'us-east-1' });
      const createInvalidationResponse = await cloudFrontClient.send(createInvalidationCommand);
      return () => monitorInvalidation(cloudFrontClient,  createInvalidationResponse.Invalidation.Id);
    } catch (cloudFrontErr) {
      console.error(cloudFrontErr);
      toast.error('Error invalidating cache on CloudFront: ' + cloudFrontErr);
      return cloudFrontErr;
    }
  } catch (s3Err) {
    console.error(s3Err);
    toast.error('Error uploading image: ' + s3Err);
    return s3Err;
  }
};

const monitorInvalidation = async (cloudFrontClient, invalidationId) => {
  const input = {
    DistributionId: DISTRIBUTION_ID,
    Id: invalidationId,
  };
  const getInvalidationCommand = new GetInvalidationCommand(input);
  let cloudFrontStatus;
  do {
    await new Promise((r) => setTimeout(r, 10000)); // sleep
    const getInvalidationResponse = await cloudFrontClient.send(getInvalidationCommand);
    cloudFrontStatus = getInvalidationResponse.Invalidation.Status;
  } while (cloudFrontStatus === 'InProgress');
  return true;
};
