import { Message } from 'semantic-ui-react';

export default ({ message }) => {
  return (
    <div className="errors">
      {message ? <Message
        error
        content={message}
      /> : <Message
        error
        content="Unknown error"
      />}
    </div>
  );
};

