import axios from 'axios';
import { Auth } from 'aws-amplify';

export default async (method, url, data) => {
  if (!['get', 'post', 'patch', 'delete', 'put'].includes(method)) {
    console.warn('Invalid method', method);
    return;
  }
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios({
      method,
      url,
      headers,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    let errorMessage = 'Server appears to be down. Contact Ben Nelson.';
    if (err.response) {
      if (err.response.data && err.response.data.errorMessage) {
        errorMessage = err.response.data.errorMessage;
      } else if (err.response.request && err.response.request.status) {
        if (err.response.request.status === 401) {
          errorMessage = 'You are not authorized to view this page. Contact Ben Nelson for permissions. Once you have permissions, log out then log back in.';
        } else {
          errorMessage = err.response.request.status + ' Response Code. Refresh the page. If the issue persists, contact Ben Nelson.';
        }
      }
    }
    return { error: true, errorMessage };
  }
};
