import { useParams } from 'react-router-dom';
import ImageUploader from '../components/ImageUploader';

export default () => {
  const params = useParams();
  return (
    <div className="team-page">
      <h2>{params.extPlayerId}</h2>
      <ImageUploader path={`players/${params.extPlayerId}-primary.png`} />
      <ImageUploader path={`players/${params.extPlayerId}-headshot.png`} />
      <ImageUploader path={`players/${params.extPlayerId}-ai.png`} />
    </div>
  );
};
